import { Controller } from "stimulus";
import { loadStripe } from "@stripe/stripe-js";

export default class extends Controller {
  static targets = ["scroll", "target"];

  connect() {
    console.log("loaded");
    this.initializeStripe();
  }

  async initializeStripe() {
    this.stripe = await loadStripe(this.element.dataset.stripePublicKey, {
      locale: document.querySelector("html").getAttribute("lang"),
    });

    // The items the customer wants to buy
    const items = [{ id: "xl-tshirt" }];
    const clientSecret = this.element.dataset.clientSecret;

    const appearance = {
      theme: "stripe",

      variables: {
        fontFamily: ' "Gill Sans", sans-serif',
        fontLineHeight: "1.5",
        borderRadius: "10px",
        colorBackground: "#F6F8FA",
        colorPrimaryText: "#DDD",
      },
      rules: {
        ".Block": {
          backgroundColor: "var(--colorBackground)",
          boxShadow: "none",
          padding: "12px",
        },
        ".Input": {
          padding: "12px",
        },
        ".Input:disabled, .Input--invalid:disabled": {
          color: "lightgray",
        },
        ".Tab": {
          padding: "10px 12px 8px 12px",
          border: "none",
        },
        ".Tab:hover": {
          border: "none",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
          border: "none",
          backgroundColor: "#fff",
          boxShadow:
            "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Label": {
          fontWeight: "500",
          color: "lightgray",
        },
      },
    };
    this.elements = this.stripe.elements({
      appearance: appearance,
      clientSecret: clientSecret,
    });

    const paymentElementOptions = {
      layout: "tabs",
      fields: {
        billingDetails: {
          address: {
            country: "never",
          },
        },
      },
    };

    const paymentElement = this.elements.create(
      "payment",
      paymentElementOptions
    );
    paymentElement.mount("#payment-element");
    document.querySelector("#submit").classList.remove("hidden");
    this.element.addEventListener("submit", (event) =>
      this.handleSubmit(event)
    );
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setLoading(true);

    let elements = this.elements;

    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.element.dataset.processPaymentUrl,
        payment_method_data: {
          billing_details: {
            address: {
              country: "fr",
            },
          },
        },
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else {
      this.showMessage("An unexpected error occurred.");
    }

    this.setLoading(false);
  }

  // Fetches the payment intent status after payment submission
  async checkStatus() {
    const clientSecret = this.element.dataset.clientSecret;

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(
      clientSecret
    );

    switch (paymentIntent.status) {
      case "succeeded":
        showMessage("Payment succeeded!");
        break;
      case "processing":
        showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        showMessage("Your payment was not successful, please try again.");
        break;
      default:
        showMessage("Something went wrong.");
        break;
    }
  }

  // ------- UI helpers -------

  showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 40000);
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  }
}
