// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

// inside app/frontend/packs/application.js
import "lightgallery.js/dist/css/lightgallery.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.js";
import "@fortawesome/fontawesome-pro/js/all.min.js";
import "masonry-layout";
import "bootstrap";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import { Application } from "@hotwired/stimulus";
import Remote from "stimulus-remote-rails";
import Carousel from "stimulus-carousel";
import Lightbox from "stimulus-lightbox";
import Autosave from "stimulus-rails-autosave";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import PlacesAutocomplete from "stimulus-places-autocomplete";
import Sortable from "stimulus-sortable";
import ContentLoader from "stimulus-content-loader";
import ReadMore from "stimulus-read-more"

import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();

window.Stimulus.register("carousel", Carousel);
window.Stimulus.register("lightbox", Lightbox);
window.Stimulus.register("remote", Remote);
window.Stimulus.register("autosave", Autosave);
window.Stimulus.register("textarea-autogrow", TextareaAutogrow);
window.Stimulus.register("places", PlacesAutocomplete);
window.Stimulus.register("sortable", Sortable);
window.Stimulus.register("content-loader", ContentLoader);
window.Stimulus.register("read-more", ReadMore)

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
