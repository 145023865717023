import Remote from "stimulus-remote-rails";

export default class extends Remote {
  static targets = ["scroll", "target"];

  connect() {
    super.connect();

    if (this.hasScrollTarget) {
      this.elementPosition =
        this.scrollTarget.getBoundingClientRect().top + window.pageYOffset;
    }
  }

  replaceTarget(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    const target = document.querySelector(this.element.dataset.target);
    if (this.element.dataset.replaceMethod == "inner") {
      target.innerHTML = xhr.response;
    } else {
      target.outerHTML = xhr.response;
    }
  }

  removeTarget(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    const target = document.querySelector(this.element.dataset.target);
    if (target) {
      target.remove();
    }
  }

  replaceWithSameForm(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;

    const doc = new DOMParser().parseFromString(xhr.response, "text/html");
    const replacement = doc.querySelector(
      '[action="' + this.element.attributes["action"].value + '"]'
    ).outerHTML;

    this.element.outerHTML = replacement;

    if (this.scrollTarget) {
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  }

  replaceModalWithResponse(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;

    this.element.closest(".modal-content").innerHTML = xhr.response;
  }

  replaceWithError(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;

    const doc = new DOMParser().parseFromString(xhr.response, "text/html");

    alert(doc.body.innerHTML);
  }
}
